<template>
  <FimButton
    v-for="provider in providers"
    :key="provider.title"
    :to="provider.url"
    type="primary"
    is-link
  >
    {{ $t('login_page.login.title') }}
  </FimButton>
</template>

<script setup lang="ts">
const props = defineProps<{
  redirects?: Record<string, string>
}>()

const providers = computed<{ title: string; url: string }[]>(() => {
  return Object.keys(props.redirects || {}).map((idpKey: string) => {
    return {
      title: idpKey,
      url: props.redirects?.[idpKey] ?? '',
    }
  })
})
</script>
