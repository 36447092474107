<template>
  <div
    class="h-full bg-[#f2f5f9] px-4 py-12 max-lg:overflow-hidden lg:min-h-[80vh] lg:px-[74px]"
    :class="{
      'space-y-2 lg:space-y-12': isOpen || isGreaterOrEquals('lg'),
      'mx-4 mt-4 !py-4': !isGreaterOrEquals('lg'),
    }"
  >
    <div
      class="flex justify-between"
      :class="{ 'pt-7': isGreaterOrEquals('lg') }"
    >
      <div class="text-sm lg:text-[28px] lg:leading-10">
        {{ $t('sign_in.basket.headline') }}
      </div>
      <div class="text-sm lg:hidden">
        {{ basketCostWithTax }}
        <LazyIconFielmannArrowDown
          class="inline size-3 shrink-0 transition"
          :class="{ '-rotate-180': isOpen }"
          @click="isOpen = !isOpen"
        />
      </div>
    </div>

    <div
      ref="content"
      class="relative transition-height"
      :class="{ 'opacity-0': !isOpen && !isGreaterOrEquals('lg') }"
      :style="
        isGreaterOrEquals('lg')
          ? {}
          : isOpen
            ? { height: 'fit-content' }
            : { height: 0 }
      "
    >
      <div v-for="pack in basketItemsByPackages" :key="pack.id">
        <div class="mb-2 flex">
          <p class="text-sm font-normal">
            {{
              `${$t(
                'osp.estimated_deliver_date',
              )} ${getDeliveryRange(pack.deliveryDate)}`
            }}
          </p>
          <CarrierLogo
            class="ml-2 !max-h-5"
            :carrier-key="pack.carrierKey"
            :carrier-name="getCarrierName(pack.carrierKey)"
          />
        </div>
        <div v-for="item in pack.items" :key="item.key">
          <SignInBasketCard :basket-item="item" />
        </div>
      </div>
      <div class="mt-8 text-xs font-semibold">
        <div class="flex justify-between py-1">
          <div>{{ $t('basket.total') }}</div>
          <div>{{ toCurrency(totalCostWithoutReductions) }}</div>
        </div>
        <div class="flex justify-between py-1">
          <div>{{ $t('basket.discount') }}</div>
          <div>
            {{
              totalDiscount
                ? withNegativePrefix(toCurrency(totalDiscount))
                : toCurrency(totalDiscount)
            }}
          </div>
        </div>
        <div class="flex justify-between py-1">
          <div>{{ $t('basket.subtotal') }}</div>
          <div>{{ basketCostWithTax }}</div>
        </div>
        <div class="flex justify-between py-1">
          <div>{{ $t('basket.shipping') }}</div>
          <div class="">
            <ProductShippingCosts :price="price" text-size="xs" />
          </div>
        </div>
        <div
          class="mt-2 flex justify-between border-t border-neutral-400 pb-1 pt-3 text-sm"
        >
          <div>{{ $t('basket.total') }}</div>
          <div class="text-right">
            {{ basketCostWithTax }}
            <div class="text-[10px]">{{ $t('basket.including_vat') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isOpen && !isGreaterOrEquals('lg')"
      class="flex space-x-2 text-[10px] font-semibold"
    >
      <span class="rounded-full bg-neutral-100 px-1.5 py-0.5">
        {{
          getLocalizedText($i18n, 'sign_in.basket.deliveries', {
            count: basketItemsByPackages.length,
          })
        }}
      </span>
      <span class="rounded-full bg-neutral-100 px-1.5 py-0.5">
        {{
          getLocalizedText($i18n, 'sign_in.basket.products', {
            count: basket.count.value,
          })
        }}
      </span>
      <span
        v-if="totalReduction"
        class="rounded-full bg-neutral-100 px-1.5 py-0.5 text-red-500"
      >
        {{ $t('sign_in.basket.discount') }}: {{ toCurrency(totalReduction) }}
      </span>
      <CarrierLogo
        v-if="basketItemsByPackages.length"
        class="ml-2 !max-h-5"
        :carrier-key="basketItemsByPackages[0].carrierKey"
        :carrier-name="getCarrierName(basketItemsByPackages[0].carrierKey)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Price } from '@scayle/storefront-nuxt'
import type { BasketPackageInformation } from '@scayle/storefront-api'
const basket = await useFimBasket({
  with: BASKET_WITH,
})

const basketItemsByPackages = computed(() =>
  groupItemsByPackages(basket.data.value?.packages, basket.items.value),
)

const getCarrierName = (carrierKey: string) => {
  return isDeliveryCarrier(carrierKey) ? CARRIER_LABELS[carrierKey] : undefined
}
const currentShop = useCurrentShop()
const getDeliveryRange = (
  deliveryDate: BasketPackageInformation['deliveryDate'],
) => {
  return `${formatDeliveryDate(deliveryDate.min, currentShop.value)} - ${formatDeliveryDate(deliveryDate.max, currentShop.value)}`
}

const isOpen = ref(true)
const { isGreaterOrEquals } = useViewport()
const $i18n = useI18n()

const basketCostWithTax = computed(() =>
  toCurrency(basket.cost.value?.withTax || 0),
)

const price = computed(() => basket.cost.value as Price)
const totalReduction = computed(() => {
  return (
    basket.cost.value?.appliedReductions?.reduce((acc, discount) => {
      return acc + discount.amount.absoluteWithTax
    }, 0) || 0
  )
})
const { totalCostWithoutReductions, totalDiscount, withNegativePrefix } =
  await useBasketReductions()
</script>
